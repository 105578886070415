.header {
  display: flex;
  justify-content: space-between;
  margin: 30px;
  margin-bottom: 50px;
}

.button {
  margin: auto;
}

.submarkLogo {
  display: block;
  margin: 0;
  height: 50px;
}
