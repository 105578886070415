.modalStyle {
  position: fixed;
  top: 30%;
  right: 40%;
  width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #eeeeee;
  border: 2px solid #000;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}

.fieldStyle {
  display: flex;
  background-color: #e6e6e6;
  border-radius: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin: 0;
}
